import React, { useEffect, useState } from 'react';
import {
    UndoIcon, RedoIcon,
    BoldIcon, ItalicIcon, UnderlineIcon, LinkIcon, PaintIcon,
    HeadingIcon, ListIcon, NumberedIcon, ImageIcon, TableIcon,
    EditTintIcon, AddImageIcon
} from '../../media';

const ToolbarIcon = props => {
    const { src, label, active, onClick } = props;

    const { popup, popupId, activePopup, setActivePopup } = props;
    const [ popupShown, setPopupShown ] = useState(false);

    useEffect(() => {
        setPopupShown(popupId && activePopup === popupId);
    }, [ activePopup ])

    return (
        <button className={`icon-button ${active || popupShown ? 'active' : ''}`} onClick={onClick}>
            {
                popup ?
                    <div
                        className={'popup-click-area'}
                        onClick={() => setActivePopup(popupShown ? null : popupId)}
                    /> :
                    null
            }
            <img src={src} alt={''}/>
            <span className={'label'}>{label}</span>
            {popup && popupShown ? popup : ''}
        </button>
    )
}

const LinkPopup = ({ format, close }) => {
    const [ value, setValue ] = useState('');

    const handleFormat = () => {
        if (value.trim()) {
            format(value);
            close();
        }
    }

    return (
        <div className={'tool-popup link'}>
            <h3>Insert Link</h3>
            <input type={'text'} placeholder={'Enter Link Here...'} onChange={event => setValue(event.target.value)}/>
            <div className={'actions'}>
                <button className={'add'} onClick={handleFormat}>Add Link</button>
                <button className={'clear'} onClick={() => format('')}>Clear Link</button>
            </div>
        </div>
    )
}

const TintPopup = ({ format }) => {
    const Cell = props =>
        <div className={'cell'} style={{ background: props.tint }} onClick={() => format(props.tint)}/>;

    const tints = [
        '#333034', '#625765', '#b8816e', '#754537',
        '#990f54', '#b81a34', '#f50723', '#f73c1b',
        '#f6760e', '#f59e07', '#fad900', '#83dc10',
        '#07bb0b', '#12db95', '#148f5c', '#0ec9c9',
        '#05cbf7', '#0b70f5', '#143891', '#7830c9',
        '#b736f7', '#fc3fe3', '#fd5a9e'
    ];

    return (
        <div className={'tool-popup tint'}>
            <h3>Change Text Colour <span className={'emoji'}>✨</span></h3>
            <div className={'colour-grid'}>
                {tints.map((tint, index) => <Cell tint={tint} key={index}/>)}
                <div className={'cell custom'} title={'Custom Text Colour'}>
                    <img src={EditTintIcon} alt={''}/>
                    <input type={'color'} className={'colour-picker'} onBlur={event => format(event.target.value)}/>
                </div>
            </div>
        </div>
    )
}

const ImagePopup = ({ insert, close }) => {
    const [ imageFile, setImageFile ] = useState('');

    const handleUpload = event => {
        const file = event.target.files[0];
        const validFileTypes = [ 'image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/svg+xml' ];

        if (file && validFileTypes.includes(file.type))
            setImageFile(file);
    }

    useEffect(() => {
        if (imageFile) {
            insert(imageFile);
            close();
        }
    }, [ imageFile ])

    return (
        <div className={'tool-popup image'}>
            <div className={'image-field'}>
                <label className={'image-button'}>
                    <img src={AddImageIcon} alt={''}/>
                    <span>Upload Image</span>
                </label>
                <input
                    className={'image-input'} onChange={handleUpload}
                    type={'file'} accept={'.png, .jpeg, .jpg, .gif, .webp, .svg'}
                />
            </div>
        </div>
    )
}

const TablePopup = ({ insert, close }) => {
    const [ tableOptions, setTableOptions ] = useState({ columns: 3, rows: 3 });
    const emptyArray = length => Array.apply(null, Array(length));

    const handleInsert = () => {
        insert(tableOptions);
        close();
    }

    return (
        <div className={'tool-popup table'}>
            <h3>Table Dimensions</h3>
            <div className={'table-preview'}>{
                emptyArray(tableOptions.rows).map(() =>
                    <div>{emptyArray(tableOptions.columns).map(() => <div/>)}</div>
                )
            }</div>
            <div className={'table-input'}>
                <input type={'number'} value={tableOptions.columns} min={1} max={16} onChange={
                    event => setTableOptions({ columns: parseInt(event.target.value), rows: tableOptions.rows })
                }/>
                <span>×</span>
                <input type={'number'} value={tableOptions.rows} min={1} max={10} onChange={
                    event => setTableOptions({ columns: tableOptions.columns, rows: parseInt(event.target.value) })
                }/>
            </div>
            <div className={'actions'}>
                <button className={'add'} onClick={handleInsert}>Add Table</button>
            </div>
        </div>
    )
}

const EditorToolbar = props => {
    const { formatMark, formatBlock, insertImage, insertTable, formatActive, operateHistory } = props;
    const [ activePopup, setActivePopup ] = useState(null);

    return (
        <div className={'toolbar'}>
            <ToolbarIcon
                src={UndoIcon} label={'Undo (ctrl+z)'}
                onClick={() => operateHistory('undo')}
            />
            <ToolbarIcon
                src={RedoIcon} label={'Redo (ctrl+y)'}
                onClick={() => operateHistory('redo')}
            />
            <div className={'divider'}/>
            <ToolbarIcon
                src={BoldIcon} label={'Bold (ctrl+b)'}
                onClick={() => formatMark('bold')} active={formatActive.bold}
            />
            <ToolbarIcon
                src={ItalicIcon} label={'Italic (ctrl+i)'}
                onClick={() => formatMark('italic')} active={formatActive.italic}
            />
            <ToolbarIcon
                src={UnderlineIcon} label={'Underline (ctrl+u)'}
                onClick={() => formatMark('underline')} active={formatActive.underline}
            />
            <ToolbarIcon
                src={LinkIcon} label={'Link (ctrl+k)'}
                popupId={'link'} activePopup={activePopup} setActivePopup={setActivePopup}
                popup={<LinkPopup format={value => formatMark('link', value)} close={() => setActivePopup(null)}/>}
            />
            <ToolbarIcon
                src={PaintIcon} label={'Colour Text ✨'}
                popupId={'tint'} activePopup={activePopup} setActivePopup={setActivePopup}
                popup={<TintPopup format={value => formatMark('tint', value)}/>}
            />
            <div className={'divider'}/>
            <ToolbarIcon
                src={HeadingIcon} label={'Heading (ctrl+shift+1)'}
                onClick={() => formatBlock('heading')} active={formatActive.heading}
            />
            <ToolbarIcon
                src={ListIcon} label={'Bulleted List'}
                onClick={() => formatBlock('bulleted-list')} active={formatActive['bulleted-list']}
            />
            <ToolbarIcon
                src={NumberedIcon} label={'Numbered List'}
                onClick={() => formatBlock('numbered-list')} active={formatActive['numbered-list']}
            />
            <ToolbarIcon
                src={ImageIcon} label={'Add Image'}
                popupId={'image'} activePopup={activePopup} setActivePopup={setActivePopup}
                popup={<ImagePopup insert={insertImage} close={() => setActivePopup(null)}/>}
            />
            <ToolbarIcon
                src={TableIcon} label={'Add Table'}
                popupId={'table'} activePopup={activePopup} setActivePopup={setActivePopup}
                popup={<TablePopup insert={options => insertTable(options)} close={() => setActivePopup(null)}/>}
            />
        </div>
    )
}

export default EditorToolbar;