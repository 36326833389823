import React, { Suspense } from 'react';

import AppRoutes from './Routes';

import './common/styles/index.css';

import '@fontsource/source-sans-3/variable.css';
import '@fontsource/source-sans-3/variable-italic.css';
import '@fontsource/roboto-serif/variable-full.css';
import '@fontsource/roboto-serif/variable-full-italic.css';
import '@fontsource/berkshire-swash';

const App = () => {
    return (
        <div id={'body'}>
            <Suspense fallback={''}>
                <AppRoutes/>
            </Suspense>
        </div>
    )
}

export default App;