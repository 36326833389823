/*** Imports  ***/

import { atom } from 'recoil';
import { niceFetch } from './common/utils';

/***  Recoil States  ***/

const lastArticleId = atom({
    key: 'lastArticleId',
    default: '0000'
})

const articleOpen = atom({
    key: 'articleOpen',
    default: false
})

const fetchCurrentAdmin = async () => await niceFetch('GET', 'schools/admins/current');
const adminLoggedInState = atom({
    key: 'adminLoggedInState',
    default: fetchCurrentAdmin().then(data => !!data.id)
})

const fetchCurrentStudent = async () => await niceFetch('GET', 'students/current');
const studentLoggedInState = atom({
    key: 'studentLoggedInState',
    default: fetchCurrentStudent().then(data => !!data.id)
})

export {
    lastArticleId, articleOpen,
    adminLoggedInState, studentLoggedInState
};