import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { adminLoggedInState } from '../../states';
import { niceFetch } from '../../common/utils';

const AdminPage = () => {
    const setAdminLoggedIn = useSetRecoilState(adminLoggedInState);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const [ loggedInAdmin, setLoggedInAdmin ] = useState();
    useEffect(() => {
        niceFetch('GET', 'schools/admins/current')
            .then(admin => setLoggedInAdmin(admin));
    }, [])

    const handleLogout = async () => {
        await niceFetch('POST', 'schools/auth/logout');
        setAdminLoggedIn(false);
        navigate('../..');
    }

    return (
        <Fragment>
            <div id={'mobile-admin-page'}>
                <h1>Please open this page on a computer.</h1>
            </div>
            <div id={'admin-page'}>
                <header>
                    <h1>St. Matthew Newspaper Admin Settings</h1>
                </header>
                <main>
                    <aside id={'sidebar'}>
                        <div
                            className={`item ${path === '/admin/pending' ? 'active' : ''}`}
                            onClick={() => navigate('./pending')}
                        >
                            <span>Pending Articles</span>
                        </div>
                        <div
                            className={`item ${path === '/admin/articles' ? 'active' : ''}`}
                            onClick={() => navigate('./articles')}
                        >
                            <span>Published Articles</span>
                        </div>
                        <div
                            className={`item ${path === '/admin/archives' ? 'active' : ''}`}
                            onClick={() => navigate('./archives')}
                        >
                            <span>Manage Archives</span>
                        </div>
                        <div
                            className={`item ${path === '/admin/students' ? 'active' : ''}`}
                            onClick={() => navigate('./students')}
                        >
                            <span>Student List</span>
                        </div>
                        <div
                            hidden={loggedInAdmin?.name !== 'Angelo'}
                            className={`item ${path === '/admin/admins' ? 'active' : ''}`}
                            onClick={() => navigate('./admins')}
                        >
                            <span>Admin List</span>
                        </div>
                        <div className={'item logout'} onClick={handleLogout}>
                            <span>Logout</span>
                        </div>
                    </aside>
                    <Outlet/>
                </main>
            </div>
        </Fragment>
    )
}

export default AdminPage;