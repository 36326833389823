import React, { useEffect, useState } from 'react';
import { CancelIcon, DeleteIcon, EditIcon } from '../../media';
import { niceFetch, pluralCount } from '../../common/utils';

const EditableContent = ({ name, setName, username, setUsername, password, setPassword, error }) => (
    <div className={'content'}>
        <div className={`row ${error === 'name' ? 'wrong' : ''}`}>
            <span>Name:</span>
            <input type={'text'} value={name} onChange={event => setName(event.target.value)}/>
        </div>
        <div className={`row ${error === 'username' ? 'wrong' : ''}`}>
            <span>Username:</span>
            <input type={'text'} value={username} onChange={event => setUsername(event.target.value)}/>
        </div>
        <div className={'row'}>
            <span>Password:</span>
            <input type={'text'} value={password} onChange={event => setPassword(event.target.value)}/>
        </div>
    </div>
)

const StudentCard = (
    {
        id, articleCount, showPassword, fetchStudents, allStudents,
        name: _name, username: _username, password: _password,
    }
) => {
    const [ editMode, setEditMode ] = useState(false);
    const [ name, setName ] = useState(_name);
    const [ username, setUsername ] = useState(_username);
    const [ password, setPassword ] = useState(_password);
    const [ error, setError ] = useState('');

    useEffect(() => {
        setEditMode(false);
        // Reset student info
        setName(_name);
        setUsername(_username);
        setPassword(_password);
    }, [ allStudents ]) // in other words, when fetch students is called

    const startProps = { name: _name, username: _username, password: _password };
    const editableProps = { name, username, password };
    const setEditableProps = { setName, setUsername, setPassword };

    const handleEdit = async () => {
        if (!editMode) setEditMode(true);
        else {
            const body = {};
            for (const [ key, value ] of Object.entries(editableProps)) {
                const setKey = 'set' + key[0].toUpperCase() + key.slice(1);
                if (value !== '' && value !== startProps[key]) body[key] = value;
                else setEditableProps[setKey](startProps[key]);
            }

            if (Object.keys(body).length === 0) {
                setEditMode(false);
                return;
            }

            const response = await niceFetch('PATCH', `students/${id}`, { body });
            if (!response.error) {
                fetchStudents();
                setEditMode(false);
            } else {
                setError(''); // reset error so animation works every click
                // also set timeout is needed for animation every click as well
                setTimeout(() => {
                    if (response.message === 'Student name in use.') setError('name');
                    else if (response.message === 'Student username in use.') setError('username');
                    // no error?
                }, 10);
            }
        }
    }

    const remove = async () => {
        await niceFetch('PATCH', `students/remove/${id}`);
        fetchStudents();
    }

    return (
        <div className={`student-item ${editMode ? 'edit-mode' : ''}`}>
            <div className={'action-bar'}>
                <button className={'action-button'} onClick={handleEdit}>
                    <img className={'icon'} src={editMode ? CancelIcon : EditIcon} alt={''}/>
                    <span>{editMode ? 'Stop Editing' : 'Edit'}</span>
                </button>
                <button className={'action-button delete'} onClick={remove}>
                    <img className={'icon'} src={DeleteIcon} alt={''}/>
                    <span>Remove</span>
                </button>
            </div>
            {
                editMode ? <EditableContent {...editableProps} {...setEditableProps} error={error}/> :
                    <div className={'content'}>
                        <h3>{_name}</h3>
                        <p className={'mini'}>{pluralCount(articleCount, 'published article')}</p>
                        <p>Username: <span>{_username}</span></p>
                        <p>Password: <span>{showPassword ? _password : '-'.repeat(_password.length)}</span></p>
                    </div>
            }
        </div>
    )
}

export default StudentCard;