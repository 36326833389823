import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArticleCard } from '../components';
import { CreateIcon } from '../../media';
import { niceFetch } from '../../common/utils';
import { useTitle } from '../../common/hooks';
import { Loading } from '../../common/components/index.js';

const Drafts = () => {
    useTitle('Your Drafts - Online Newspaper');

    const navigate = useNavigate();
    const [ articles, setArticles ] = useState();

    const fetchArticles = async () => {
        const fetchedArticles = await niceFetch('GET', 'articles/drafts');
        setArticles(fetchedArticles);
    }

    useEffect(() => {
        fetchArticles();
    }, [])

    return (
        <section className={'article-grid'}>
            <div className={'new-card'} onClick={() => navigate('../editor')}>
                <img src={CreateIcon} alt={''}/>
                <span>Write New Article</span>
            </div>
            {
                !articles ? <Loading/> :
                    articles.map((article, index) =>
                        <ArticleCard
                            key={index} id={article.id}
                            title={article.title} thumbnail={article.thumbnail}
                            edited={article.edited} status={article.status}
                            archived={article.archived} fetchArticles={fetchArticles}
                        />
                    )
            }
        </section>
    )
}

export default Drafts;