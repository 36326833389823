import dotenv from 'dotenv';

dotenv.config();

export const apiFetch = async (method, url, _options = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        ..._options.headers
    };
    const options = {
        credentials: 'include',
        ..._options, headers,
        body: _options.body ? JSON.stringify(_options.body) : undefined
    };

    const response = await fetch(url, { method, ...options });
    return response.json();
};

export const niceFetch = async (method, url, options) => {
    const schoolId = '12345678';
    const prefix = process.env.REACT_APP_ENV === 'DEV' ?
        `http://localhost:3020/${schoolId}/` :
        `https://mattnewsapi.djeumene.ca/${schoolId}/`;
    return apiFetch(method, prefix + url, options);
}