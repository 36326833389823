import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { adminLoggedInState } from '../../states';
import { niceFetch } from '../../common/utils';
import { useTitle } from '../../common/hooks';

import '../../common/styles/login.css';

const AdminLogin = () => {
    useTitle('Teacher Admin Login - St. Matthew Online Newspaper');

    const [ isAdminLoggedIn, setAdminLoggedIn ] = useRecoilState(adminLoggedInState);

    const navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');

    const handleLogin = async () => {
        const body = { email, password };
        const response = await niceFetch('POST', 'schools/auth/login', { body });

        if (!response.error) setAdminLoggedIn(true);
        else {
            setError(''); // reset error so animation works every click
            // also set timeout is needed for animation every click as well
            setTimeout(() => {
                if (response.message[0] === 'email must be an email') setError('email');
                else setError('password');
            }, 10);
        }
    }

    useEffect(() => {
        if (isAdminLoggedIn) navigate('./admin');
    })

    return (
        <div className={'login-container admin'}>
            <div className={'login-card'}>
                <Link to={'../..'} className={'back-button'}>&lt; Back</Link>
                <h1>Teacher Admin Login</h1>
                <div className={`text-field ${error === 'email' ? 'wrong' : ''}`}>
                    <p>Email</p>
                    <input
                        type={'email'} placeholder={'Type email...'}
                        onChange={event => setEmail(event.target.value)}
                    />
                </div>
                <div className={`text-field ${error === 'password' ? 'wrong' : ''}`}>
                    <p>Password</p>
                    <input
                        type={'password'} placeholder={'Type password...'}
                        onChange={event => setPassword(event.target.value)}
                    />
                </div>
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    )
}

export default AdminLogin;