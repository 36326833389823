import React, { useEffect, useState } from 'react';
import randomWords from 'random-words';
import { StudentCard } from '../components';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const AddStudentCard = ({ closeCard, fetchStudents }) => {
    const [ name, setName ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');
    const [ generatedUsername, setGeneratedUsername ] = useState('');
    const [ generatedPassword, setGeneratedPassword ] = useState('');

    const generateUsername = name => {
        if (!name) return '';
        const [ firstName, secondName ] = name.toLowerCase().split(' '); // second name can be last name or a second first name
        const randomNumber = Math.floor(Math.random() * 900) + 100; // random 3 digit number
        const secondInitial = secondName ? secondName[0] : '';
        return firstName + secondInitial + randomNumber.toString();
    }

    const generatePassword = () => {
        let [ firstWord, secondWord ] = randomWords({ exactly: 2, maxLength: 8 });
        const randomNumber = Math.floor(Math.random() * 900) + 100; // random 3 digit number
        firstWord = firstWord[0].toUpperCase() + firstWord.slice(1);
        secondWord = secondWord[0].toUpperCase() + secondWord.slice(1);
        return firstWord + secondWord + randomNumber.toString();
    }

    useEffect(() => {
        setGeneratedUsername(generateUsername(name));
        setGeneratedPassword(generatePassword(name));
    }, [ name ])

    const createStudent = async () => {
        if (!name || !username || !password) {
            setError(''); // reset error so animation works every click
            // also set timeout is needed for animation every click as well
            setTimeout(() => {
                if (!name) setError('name');
                else if (!username) setError('username');
                else if (!password) setError('password');
            }, 10);
        } else {
            const body = { name, username, password };
            const response = await niceFetch('POST', 'students', { body });

            if (!response.error) {
                closeCard();
                fetchStudents();
            } else {
                setError(''); // reset error so animation works every click
                // also set timeout is needed for animation every click as well
                setTimeout(() => {
                    if (response.message[0] === 'name must be an string' ||
                        response.message === 'Student name in use.') setError('name');
                    else if (response.message[0] === 'username must be an string' ||
                        response.message === 'Student username in use.') setError('username');
                    else if (response.message[0] === 'password must be an string') setError('password');
                    // no error?
                }, 10);
            }
        }
    }

    return (
        <div className={'add-student-card'}>
            <div className={`item ${error === 'name' ? 'wrong' : ''}`}>
                <span>Name:</span>
                <input
                    type={'text'} placeholder={'Student full name...'}
                    value={name} onChange={event => setName(event.target.value)}
                />
            </div>
            <div className={'row'}>
                <div className={`item ${error === 'username' ? 'wrong' : ''}`}>
                    <span>Username:</span>
                    <input
                        type={'text'}
                        placeholder={name.length > 2 ? `ex. ${generatedUsername}` : 'Student login username...'}
                        value={username} onChange={event => setUsername(event.target.value)}
                        onFocus={() => !username && name.length > 2 ? setUsername(generatedUsername) : null}
                    />
                </div>
                <div className={`item ${error === 'password' ? 'wrong' : ''}`}>
                    <span>Password:</span>
                    <input
                        type={'text'}
                        placeholder={name.length > 2 ? `ex. ${generatedPassword}` : 'Student login password...'}
                        value={password} onChange={event => setPassword(event.target.value)}
                        onFocus={() => !password && name.length > 2 ? setPassword(generatedPassword) : null}
                    />
                </div>
            </div>
            <div className={'row'}>
                <button className={'action-button'} onClick={closeCard}>Cancel</button>
                <button className={'action-button add'} onClick={createStudent}>Create Student</button>
            </div>
        </div>
    )
}

const StudentList = () => {
    useTitle('List of Students - St. Matthew Newspaper Admin Settings');

    const [ loading, setLoading ] = useState(false);
    const [ students, setStudents ] = useState([]);
    const [ addStudentShown, setAddStudentShown ] = useState(false);
    const [ showPasswords, setShowPasswords ] = useState(false);

    const sortStudents = (a, b) => a.name.localeCompare(b.name);

    const fetchStudents = async () => {
        setLoading(true);
        const fetchedStudents = await niceFetch('GET', 'students');
        setStudents(fetchedStudents);
        setLoading(false);
    }

    useEffect(() => {
        fetchStudents();
    }, [])

    return (
        <section id={'student-list'}>
            <div className={'top'}>
                <h2>List of Students</h2>
                <button className={'action-button add'} onClick={() => setAddStudentShown(true)}>Add Student</button>
                {addStudentShown ?
                    <AddStudentCard closeCard={() => setAddStudentShown(false)} fetchStudents={fetchStudents}/> : ''}
            </div>
            <div className={'subtitle'}>
                <span>{pluralCount(students.length, 'valid student')}</span>
                <div className={'check'}>
                    <input type={'checkbox'} onChange={event => setShowPasswords(event.target.checked)}/>
                    <span>Show Passwords</span>
                </div>
            </div>
            <div className={'grid-container'}>{
                loading ? <Loading/> :
                    students.sort(sortStudents).map((student, index) =>
                        <StudentCard
                            key={index} id={student.id} name={student.name}
                            username={student.username} articleCount={student.articleCount}
                            password={student.password} showPassword={showPasswords}
                            fetchStudents={fetchStudents} allStudents={students}
                        />
                    )
            }</div>
        </section>
    )
}

export default StudentList;