import { fromMarkdown } from 'mdast-util-from-markdown';
import { toHast } from 'mdast-util-to-hast';

import { mdxjs } from 'micromark-extension-mdxjs';
import { gfmTable } from 'micromark-extension-gfm-table';

import { mdxFromMarkdown } from 'mdast-util-mdx';
import { gfmTableFromMarkdown } from 'mdast-util-gfm-table';

export const rehypeFix = () => (tree, file) => {
    if (!file.value) return;

    const mdastTree = fromMarkdown(file.value, {
        extensions: [ mdxjs(), gfmTable ],
        mdastExtensions: [ mdxFromMarkdown(), gfmTableFromMarkdown ]
    });

    const hastTree = toHast(mdastTree, {
        handlers: {
            // this handler prevents mdx elements from being affected
            mdxJsxFlowElement: (h, node) => node,
            mdxJsxTextElement: (h, node) => node
        }
    });

    tree.children = hastTree.children;
}