import { Link } from 'react-router-dom';
import { useTitle } from '../../common/hooks';

const NotFound = () => {
    useTitle('Page Not Found');

    return (
        <div className={'not-found'}>
            <h1>Page Not Found</h1>
            <Link to={'/'}>Go to home page</Link>
        </div>
    )
}

export default NotFound;