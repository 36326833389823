import React from 'react';
import { DeleteIcon, ReadIcon } from '../../media';
import { DateTime } from 'luxon';
import { articleLink, niceFetch } from '../../common/utils';

const ArticleCard = ({ id, title, author, timestamp: _timestamp, fetchArticles }) => {
    /*  Timestamp Stuff  */
    const timestamp = DateTime.fromISO(_timestamp);
    const timestampFormat = 'LLLL d, y (h:mm a)';
    const published = timestamp.toFormat(timestampFormat);

    /*  Fetches  */

    const read = () => {
        // Open in new tab
        window.open(`../../${articleLink(id, title)}`);
    }

    const unpublish = async () => {
        await niceFetch('PATCH', `articles/publishing/${id}?action=unpublish`)
        fetchArticles();
    }

    return (
        <div className={'article-card'}>
            <div className={'content'}>
                <p className={'detail'}>Published on: <span>{published}</span></p>
                <h3>{title}</h3>
                <p className={'detail'}>Written by <span>{author}</span></p>
            </div>
            <div className={'actions'}>
                <button className={'action-button'} onClick={read}>
                    <img className={'icon'} src={ReadIcon} alt={''}/>
                    <span>Read</span>
                </button>
                <button className={'action-button delete'} onClick={unpublish}>
                    <img className={'icon'} src={DeleteIcon} alt={''}/>
                    <span>Unpublish</span>
                </button>
            </div>
        </div>
    )
}

export default ArticleCard;