import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { studentLoggedInState } from '../../states';
import { niceFetch } from '../../common/utils';

const StudentPage = () => {
    const setStudentLoggedIn = useSetRecoilState(studentLoggedInState);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const [ studentName, setStudentName ] = useState('');

    const handleLogout = async () => {
        await niceFetch('POST', 'students/auth/logout');
        setStudentLoggedIn(false);
        navigate('../..');
    }

    /*  Fetches  */

    const fetchStudent = async () => {
        const fetchedStudent = await niceFetch('GET', 'students/current');
        setStudentName(fetchedStudent.name);
    }

    useEffect(() => {
        fetchStudent();
    }, []);

    return (
        <main id={'student-page'}>
            <section className={'head'}>
                <h1>Welcome, <span>{studentName}</span></h1>
                <div className={'tabs'}>
                    <Link className={`item ${path === '/student/articles' ? 'active' : ''}`}
                          to={'./articles'}>Articles</Link>
                    <Link className={`item ${path === '/student/drafts' ? 'active' : ''}`} to={'./drafts'}>Drafts</Link>
                    <span className={'item logout'} onClick={handleLogout}>Logout</span>
                </div>
            </section>
            <Outlet/>
        </main>
    )
}

export default StudentPage;