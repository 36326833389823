import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Element as SlateElement, Transforms, Node as SlateNode, Range } from 'slate';
import { Slate, Editable, withReact, useSelected, useSlate, ReactEditor } from 'slate-react';
import { HistoryEditor, withHistory } from 'slate-history';

import { EditorToolbar, withTables } from './';
import { CaptionIcon, CenterImageIcon, DeleteIcon, LeftImageIcon, RightImageIcon } from '../../media';
import { editorToMdx, getImage, mdxToEditor, postImage, removeImage } from '../../common/utils';

// Thanks to https://www.slatejs.org/examples/richtext
// Source: https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx

/**  Editor Helper Methods  **/

/*  History  */

const historyOperation = (editor, operation) => {
    if (operation === 'undo') HistoryEditor.undo(editor);
    else if (operation === 'redo') HistoryEditor.redo(editor);
}

/*  Toggles / Inserts  */

const toggleMark = (editor, format, value = true) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) Editor.removeMark(editor, format);
    else Editor.addMark(editor, format, value);
}

const toggleBlock = (editor, format) => {
    const listTypes = [ 'bulleted-list', 'numbered-list' ];
    const isActive = isBlockActive(editor, format);
    const isList = listTypes.includes(format);

    // Unwrap Lists
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            listTypes.includes(n.type),
        split: true,
    })

    const newProperties = { type: isActive ? 'paragraph' : isList ? 'list-item' : format };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
}

const insertTable = (editor, options) => {
    const emptyArray = length => Array.apply(null, Array(length));

    const table = {
        type: 'table',
        children: emptyArray(options.rows).map(() => ({
            type: 'table-row',
            children: emptyArray(options.columns).map(() => ({
                type: 'table-cell',
                children: [ { text: '' } ]
            }))
        }))
    }

    const [ currentNode ] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n),
    })

    const [ currentElement ] = currentNode;
    if (currentElement.type === 'paragraph')
        Transforms.insertNodes(editor, table);
}

const insertImage = async (editor, file, align = 'center', caption = '') => {
    if (!editor.selection) return;

    const { image: { id } } = await postImage(file, editor.articleId);
    editor.addImageId(id);

    const image = {
        type: 'image', children: [ { text: '' } ],
        id, align, caption, selected: true
    };

    if (editor.selection)
        Transforms.insertNodes(editor, image, { at: [ editor.selection.focus.path[0] ] });
    else Transforms.insertNodes(editor, image, { at: [ editor.children.length ] });
}

/*  Is Active  */

const isBlockActive = (editor, format) => {
    const { selection } = editor;
    if (!selection) return false;

    const [ match ] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        })
    )

    return !!match;
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
}

/**  Images  **/

const withImages = editor => {
    const { insertData, isVoid, deleteBackward, deleteForward } = editor;

    editor.isVoid = element => element.type === 'image' ? true : isVoid(element);

    editor.insertData = data => {
        const { files } = data;

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader();
                const [ mime ] = file.type.split('/');

                if (mime === 'image') {
                    reader.addEventListener('load', () => insertImage(editor, file));
                    reader.readAsDataURL(file);
                }
            }
        } else insertData(data);
    }

    editor.deleteBackward = unit => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const cursor = selection.anchor;
            const [ node ] = Editor.node(editor, [ cursor.path[0] ]);

            if (node.type === 'image') return;
            else if (cursor.offset === 0 && cursor.path[0] > 0) {
                const [ previousNode ] = Editor.node(editor, [ cursor.path[0] - 1 ]);
                if (previousNode.type === 'image') return;
            }
        }

        deleteBackward(unit);
    }

    editor.deleteForward = unit => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const cursor = selection.anchor;
            const [ node, nodePath ] = Editor.node(editor, [ cursor.path[0] ]);

            if (node.type === 'image') return;
            else if (cursor.offset === Editor.end(editor, nodePath).offset && cursor.path[0] < editor.children.length) {
                const [ nextNode ] = Editor.node(editor, [ cursor.path[0] + 1 ]);
                if (nextNode.type === 'image') return;
            }
        }

        deleteForward(unit);
    }

    return editor;
}

const EditorImage = ({ attributes, children, element }) => {
    const [ align, setAlign ] = useState(element.align); // center by default
    const [ caption, setCaption ] = useState(element.caption);
    const [ showCaption, setShowCaption ] = useState(!!element.caption);
    const editor = useSlate();
    const selected = useSelected();
    const path = ReactEditor.findPath(editor, element);

    const deleteImage = async () => {
        editor.removeImageId(element.id);
        Transforms.removeNodes(editor, { at: path });
    }

    const handleCaption = () => {
        if (!showCaption) {
            setShowCaption(true);
            setCaption('Caption')
        } else setShowCaption(false);
    }

    useEffect(() => { Transforms.setNodes(editor, { selected }, { at: path }) }, [ selected ]);
    useEffect(() => { Transforms.setNodes(editor, { align }, { at: path }) }, [ align ]);
    useEffect(() => { Transforms.setNodes(editor, { caption }, { at: path }) }, [ caption ]);

    const captionInputElement = useRef(null);

    const handleCaptionText = () => {
        setCaption(captionInputElement.current.value);
        captionInputElement.current.style.height = 'auto';
        captionInputElement.current.style.height = captionInputElement.current.scrollHeight + 'px';
    }

    return (
        <div {...attributes} className={`editor-image-wrapper ${element.align}`}>
            {children}
            <div className={`editor-image ${element.align} ${element.selected ? 'selected' : ''}`}
                 contentEditable={false}>
                <div className={'actions'}>
                    <button className={align === 'left' ? 'active' : ''} onClick={() => setAlign('left')}>
                        <img src={LeftImageIcon} alt={''}/>
                    </button>
                    <button className={align === 'center' ? 'active' : ''} onClick={() => setAlign('center')}>
                        <img src={CenterImageIcon} alt={''}/>
                    </button>
                    <button className={align === 'right' ? 'active' : ''} onClick={() => setAlign('right')}>
                        <img src={RightImageIcon} alt={''}/>
                    </button>
                    <button className={showCaption ? 'active toggle' : ''} onClick={handleCaption}>
                        <img src={CaptionIcon} alt={''}/>
                    </button>
                    <button className={'remove'} onClick={deleteImage}>
                        <img src={DeleteIcon} alt={''}/>
                    </button>
                </div>
                <img src={getImage(element.id)} alt={''}/>
                {showCaption ?
                    <textarea className={'caption'} ref={captionInputElement} value={caption}
                              onChange={handleCaptionText} rows={1}/> : ''}
            </div>
        </div>
    )
}

/**  Rendering Components  **/

const Element = props => {
    const { attributes, children, element } = props;
    switch (element.type) {
        default:
        case 'paragraph':
            return <p {...attributes}>{children}</p>;
        case 'heading':
            return <h2 {...attributes} className={'heading'}>{children}</h2>;

        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;

        case 'image':
            return <EditorImage {...props}/>;

        case 'table':
            return <table>
                <tbody {...attributes}>{children}</tbody>
            </table>
        case 'table-row':
            return <tr {...attributes}>{children}</tr>;
        case 'table-cell':
            return <td {...attributes}>{children}</td>;
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.tint) // Tint is first for color style priority
        children = <span style={{ color: leaf.tint }}>{children}</span>;

    if (leaf.bold)
        children = <b>{children}</b>;

    if (leaf.italic)
        children = <i>{children}</i>;

    if (leaf.underline)
        children = <u>{children}</u>;

    if (leaf.link) {
        const openLink = event => {
            if (event.ctrlKey || event.metaKey)
                window.open(leaf.link).focus(); // focus doesn't work in Chrome :(
        }

        children = <span className={'link-leaf'} onClick={openLink} title={'Ctrl+Click to open'}>{children}</span>;
    }

    return <span {...attributes}>{children}</span>
}

const applyPlugins = (editor, plugins) => {
    let appliedEditor = editor;
    for (const plugin of plugins)
        appliedEditor = plugin(appliedEditor);
    return appliedEditor;
}

/**  Editor Component  **/

const ArticleEditor = ({ article, setArticleContent, unsave, addImageId, removeImageId }) => {
    const withImageIds = editor => {
        editor.addImageId = addImageId;
        editor.removeImageId = removeImageId;
        if (article) editor.articleId = article.id;
        return editor;
    }

    const plugins = [ withReact, withHistory, withImages, withTables, withImageIds ];
    const editor = useMemo(() => applyPlugins(createEditor(), plugins), []);

    useEffect(() => {
        if (article) {
            const editorContent = mdxToEditor(article.content);
            // console.log('editorContent', editorContent);
            if (editorContent.length > 0) {
                setValue(editorContent);
                editor.children = editorContent;
            }
        }
    }, [ article ])

    const initialValue = [
        {
            type: 'paragraph',
            children: [ { text: '' } ],
        }
    ];
    const [ value, setValue ] = useState(initialValue);
    useEffect(() => { setArticleContent(editorToMdx(value)) }, [ value ]);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    // Helpers for Toolbar
    const toolbarHelpers = {
        formatMark: (format, value) => toggleMark(editor, format, value),
        formatBlock: format => toggleBlock(editor, format),
        insertImage: file => insertImage(editor, file),
        insertTable: options => insertTable(editor, options),
        operateHistory: operation => historyOperation(editor, operation)
    };

    let isMarkFormat = format => isMarkActive(editor, format);
    let isBlockFormat = format => isBlockActive(editor, format);

    const [ formatActive, setFormatActive ] = useState({
        bold: false,
        italic: false,
        underline: false,
        heading: false,
        'bulleted-list': false,
        'numbered-list': false
    });

    return (
        <div className={'article-editor'}>
            <EditorToolbar formatActive={formatActive} {...toolbarHelpers} />
            <Slate
                editor={editor} value={value}
                onChange={newValue => {
                    unsave();
                    setValue(newValue);
                    // const mdxString = editorToMdx(newValue);
                    // console.log('editor to mdx', mdxString);
                    // console.log('\n--\n')
                    // console.log('mdx to editor', mdxToEditor(mdxString));
                    // console.log('\n------\n')

                    /*  Set active formats (for toolbar icons)  */
                    const newFormatActive = {};
                    for (const format of Object.keys(formatActive))
                        newFormatActive[format] = isMarkFormat(format) || isBlockFormat(format);
                    setFormatActive(newFormatActive);

                    /*  Set standalone table cells to paragraphs  */
                    const [ cell ] = Editor.nodes(editor, {
                        match: n =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            n.type === 'table-cell',
                    })

                    if (cell) {
                        const [ , cellPath ] = cell;

                        if (SlateNode.parent(editor, cellPath).type !== 'table-row')
                            Transforms.setNodes(editor, { type: 'paragraph' }, { at: cellPath });
                    }

                    /*  Set standalone lists to paragraphs (lists with no children)  */
                    const lists = Editor.nodes(editor, {
                        match: n =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            n.descendants(n).length > 0 &&
                            n.type.endsWith('list')
                    })

                    if (lists.length > 0)
                        lists.forEach(list => {
                            const [ /* unused listNode */, listPath ] = list;
                            Transforms.setNodes(editor, { type: 'paragraph' }, { at: listPath });
                        });

                    /*  Add empty paragraph node at the end if there isn't one  */
                    const lastNode = editor.children[editor.children.length - 1];

                    if (lastNode.type !== 'paragraph' || lastNode.children[0].text !== '') {
                        const emptyParagraph = { type: 'paragraph', children: [ { text: '' } ] };
                        Transforms.insertNodes(editor, emptyParagraph, { at: [ editor.children.length ] });
                    }
                }}
            >
                <Editable
                    renderElement={renderElement} renderLeaf={renderLeaf}
                    placeholder={'Start writing your article here...'}
                    onDrop={event => {
                        if (editor.selection) {
                            const [ node, nodePath ] = Editor.node(editor, [ editor.selection.anchor.path[0] ]);

                            if (node.type === 'image') {
                                const targetNode = ReactEditor.toSlateNode(editor, event.target);
                                const targetPath = ReactEditor.findPath(editor, targetNode);
                                Transforms.moveNodes(editor, { at: nodePath, to: [ Math.max(0, targetPath[0] - 1) ] });
                                event.preventDefault();
                            }
                        }
                    }}
                    onBlur={event => {
                        if (event.relatedTarget?.classList.contains('icon-button') || event.relatedTarget?.classList.contains('tool-popup'))
                            event.target.focus();
                        else {
                            const newFormatActive = {};
                            for (const format of Object.keys(formatActive))
                                newFormatActive[format] = false;
                            setFormatActive(newFormatActive);
                        }
                    }}
                />
            </Slate>
        </div>
    )
}

export default ArticleEditor;