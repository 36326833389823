import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import * as JsSearch from 'js-search';
import { ArticleCard } from '../components';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const PublishedArticles = () => {
    useTitle('Published Articles - St. Matthew Newspaper Admin Settings');

    const [ loading, setLoading ] = useState(false);
    const [ articles, setArticles ] = useState([]);
    const [ archives, setArchives ] = useState({});
    const [ selectedArchive, setSelectedArchive ] = useState();
    const [ searchValue, setSearchValue ] = useState();
    const [ articleItems, setArticleItems ] = useState();

    const sortArticles = (a, b) => {
        if (!a || !b) return 0;
        const timeA = DateTime.fromISO(a.published).valueOf();
        const timeB = DateTime.fromISO(b.published).valueOf();
        return timeB - timeA;
    }

    /*  Fetches  */

    const fetchArticles = async () => {
        setLoading(true);
        const archiveId = Object.keys(archives).find(id => archives[id] === selectedArchive);
        const query = selectedArchive && selectedArchive !== 'All' ? `?archive=${archiveId}` : '';
        const fetchedArticles = await niceFetch('GET', 'articles' + query);
        setArticles(fetchedArticles.sort(sortArticles));
        setLoading(false);
    }

    const fetchArchives = async () => {
        const fetchedArchives = await niceFetch('GET', 'archives');

        const sortArchives = (a, b) => b.start - a.start;
        const archivesList = {};
        fetchedArchives.sort(sortArchives).forEach(archive => {
            archivesList[archive.id] = archive.start + '-' + archive.end;
        });
        setArchives(archivesList);
    }

    useEffect(() => { fetchArchives() }, []);
    useEffect(() => {
        fetchArticles();
    }, [ selectedArchive ]);

    /*  Search  */

    const search = new JsSearch.Search('id');
    search.addDocuments(articles);
    search.addIndex('title');
    search.addIndex('authorName');

    useEffect(() => {
        if (searchValue === '')
            setArticleItems(articles);
        else
            setArticleItems(search.search(searchValue));
    }, [ articles, searchValue ])

    return (
        <section id={'article-list'}>
            <div className={'top'}>
                <h2>Published Articles</h2>
            </div>
            <p className={'subtitle'}>
                {pluralCount(articles.length, 'article')} in&nbsp;
                <b>{selectedArchive === 'All' || selectedArchive === undefined ? 'total' : selectedArchive}</b>
            </p>
            <div className={'search-bar'}>
                <input
                    className={'search-field'} type={'text'}
                    placeholder={'Search for articles and authors...'}
                    onChange={event => setSearchValue(event.target.value)}
                />
                <select className={'select-field'} onChange={event => setSelectedArchive(event.target.value)}>
                    <option>All</option>
                    {Object.values(archives).map((archive, index) => <option key={index}>{archive}</option>)}
                </select>
            </div>
            <div className={'grid-container'}>{
                loading ? <Loading/> :
                    (searchValue ? search.search(searchValue) : articles).map((article, index) =>
                        <ArticleCard
                            key={index} id={article.id} title={article.title}
                            author={article.authorName} timestamp={article.published}
                            fetchArticles={fetchArticles}
                        />
                    )
            }</div>
        </section>
    )
}

export default PublishedArticles;