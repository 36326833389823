import { map } from 'unist-util-map';
import { visit } from 'unist-util-visit';
import { remove } from 'unist-util-remove';
import { parents } from 'unist-util-parents';

import { fromMarkdown } from 'mdast-util-from-markdown';
import { toMarkdown } from 'mdast-util-to-markdown';
import { mdxjs } from 'micromark-extension-mdxjs';
import { gfmTable } from 'micromark-extension-gfm-table';
import { mdxFromMarkdown, mdxToMarkdown } from 'mdast-util-mdx';
import { gfmTableFromMarkdown, gfmTableToMarkdown } from 'mdast-util-gfm-table';

const isFormattedText = node =>
    node.type === 'strong' || node.type === 'emphasis' ||
    node.name === 'B' || node.name === 'I' || node.name === 'U' ||
    node.name === 'Tint' || node.type === 'link';

const findNode = (node, input) => {
    if (node.node === input)
        return node;
    else if (node.children) {
        for (const n of node.children) {
            const found = findNode(n, input);
            if (found) return found;
        }

        return null;
    }
}

const removeTextChildren = node => {
    if (node.children && node.children.length > 0)
        for (const n of node.children) removeTextChildren(n);
    else if (node.text)
        delete node.children;
}

const fixImageNodes = node => {
    if (node.children && node.children.length > 0)
        for (const n of node.children) {
            if (n.type === 'image')
                n.children = [ { text: '' } ];
            else removeTextChildren(n);
        }
}

export const mdxToEditor = articleContent => {
    const articleTree = fromMarkdown(articleContent, {
        extensions: [ mdxjs(), gfmTable ],
        mdastExtensions: [ mdxFromMarkdown(), gfmTableFromMarkdown ]
    });
    // console.log('article tree', articleTree);

    let editorTree = map(articleTree, node => {
        delete node.position;

        // heading works automatically :)

        /*  Text Formatting (bold, italic, underline, tints and links)  */

        if (node.type === 'text') {
            let textNode = { text: node.value };

            const parentedTree = parents(articleTree);
            let currentNode = findNode(parentedTree, node);

            while (isFormattedText(currentNode.parent)) {
                const parent = currentNode.parent;

                if (parent.name === 'B' || parent.type === 'strong')
                    textNode.bold = true;

                if (parent.name === 'I' || parent.type === 'emphasis')
                    textNode.italic = true;

                if (parent.name === 'U')
                    textNode.underline = true;

                if (parent.name === 'Tint' && parent.attributes[0].value !== '#333034') // #333034 is default color
                    textNode.tint = parent.attributes[0].value;

                if (parent.type === 'link')
                    textNode.link = parent.url;

                currentNode = parent;
            }

            return textNode;
        }

        /*  Lists (bulleted, ordered and items)  */

        if (node.type === 'list')
            return { ...node, type: node.ordered ? 'numbered-list' : 'bulleted-list' };

        if (node.type === 'listItem')
            return { ...node, type: 'list-item' };

        /*  Images (images and rows)  */

        if (node.name === 'Image')
            return {
                type: 'image',
                id: node.attributes.find(attr => attr.name === 'id').value,
                align: node.attributes.find(attr => attr.name === 'align').value,
                caption: node.attributes.find(attr => attr.name === 'caption').value
            }

        /*  Tables (rows and cells)  */

        // table works automatically :)

        if (node.type === 'tableRow')
            return { ...node, type: 'table-row' };

        if (node.type === 'tableCell')
            return { ...node, type: 'table-cell' };

        return node;
    });
    // console.log('first iteration', editorTree);

    let formatNodeCount = 0;
    visit(editorTree, node => isFormattedText(node) ? formatNodeCount++ : null);

    const replaceFormatting = () => {
        editorTree = map(editorTree, node => {
            // replace parent formatted text nodes with their child text node
            if (isFormattedText(node)) return node.children[0];
            return node;
        });
    }

    for (let i = 0; i < formatNodeCount; i++)
        replaceFormatting();

    // console.log('second iteration', editorTree);

    // remove all children of text nodes
    remove(editorTree, { cascade: false }, (n, i, parent) => parent && parent.text);
    removeTextChildren(editorTree);
    // ...and add empty text nodes in images (required for editor)
    fixImageNodes(editorTree);

    // console.log('article -> editor tree', editorTree);

    return editorTree.children;
}

export const editorToMdx = editorValue => {
    const editorTree = { type: 'root', children: editorValue };
    // console.log('editor tree', editorTree);

    const articleTree = map(editorTree, node => {
        // heading works automatically :)

        /*  Text Formatting (bold, italic, underline, tints and links)  */

        if (node.text !== undefined) {
            let textNode = { type: 'text', value: node.text };

            if (node.bold)
                textNode = { type: 'mdxJsxTextElement', name: 'B', children: [ textNode ] };

            if (node.italic)
                textNode = { type: 'mdxJsxTextElement', name: 'I', children: [ textNode ] };

            if (node.underline)
                textNode = { type: 'mdxJsxTextElement', name: 'U', children: [ textNode ] };

            if (node.tint && node.tint !== '#333034') // #333034 is default color
                textNode = {
                    type: 'mdxJsxTextElement', name: 'Tint',
                    attributes: [ { type: 'mdxJsxAttribute', name: 'color', value: node.tint } ],
                    children: [ textNode ]
                };

            if (node.link)
                textNode = { type: 'link', url: node.link, children: [ textNode ] };

            return textNode;
        }

        /*  Lists (bulleted, ordered and items)  */

        if (node.type.endsWith('list'))
            return { ...node, type: 'list', ordered: node.type === 'numbered-list' };

        if (node.type === 'list-item')
            return { ...node, type: 'listItem' };

        /*  Images (images and rows)  */

        if (node.type === 'image')
            return {
                type: 'mdxJsxFlowElement', name: 'Image',
                attributes: [
                    { type: 'mdxJsxAttribute', name: 'id', value: node.id },
                    { type: 'mdxJsxAttribute', name: 'align', value: node.align },
                    { type: 'mdxJsxAttribute', name: 'caption', value: node.caption }
                ]
            }

        /*  Tables (rows and cells)  */

        // table works automatically :)

        if (node.type === 'table-row')
            return { ...node, type: 'tableRow' };

        if (node.type === 'table-cell')
            return { ...node, type: 'tableCell' };

        return node;
    });

    // console.log('editor -> article tree', articleTree);

    return toMarkdown(articleTree, { extensions: [ mdxToMarkdown(), gfmTableToMarkdown() ] });
}