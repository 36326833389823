import React, { useEffect, useState } from 'react';
import { AdminCard } from '../components';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const AddAdminCard = ({ closeCard, fetchAdmins }) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');

    const createAdmin = async () => {
        if (!name || !email || !password) {
            setError(''); // reset error so animation works every click
            // also set timeout is needed for animation every click as well
            setTimeout(() => {
                if (!name) setError('name');
                else if (!email) setError('email');
                else if (!password) setError('password');
            }, 10);
        } else {
            const body = { name, email, password };
            const response = await niceFetch('POST', 'schools/admins', { body });

            if (!response.error) {
                closeCard();
                fetchAdmins();
            } else {
                setError(''); // reset error so animation works every click
                // also set timeout is needed for animation every click as well
                setTimeout(() => {
                    if (response.message[0] === 'name must be an string' ||
                        response.message === 'Student name in use.') setError('name');
                    else if (response.message[0] === 'username must be an string' ||
                        response.message === 'Student username in use.') setError('username');
                    else if (response.message[0] === 'password must be an string') setError('password');
                    // no error?
                }, 10);
            }
        }
    }

    return (
        <div className={'add-admin-card'}>
            <div className={`item ${error === 'name' ? 'wrong' : ''}`}>
                <span>Name:</span>
                <input
                    type={'text'} placeholder={'Admin name...'}
                    value={name} onChange={event => setName(event.target.value)}
                />
            </div>
            <div className={'row'}>
                <div className={`item ${error === 'email' ? 'wrong' : ''}`}>
                    <span>Email:</span>
                    <input
                        type={'text'}
                        placeholder={'Admin login username...'}
                        value={email} onChange={event => setEmail(event.target.value)}
                    />
                </div>
                <div className={`item ${error === 'password' ? 'wrong' : ''}`}>
                    <span>Password:</span>
                    <input
                        type={'text'}
                        placeholder={'Admin login password...'}
                        value={password} onChange={event => setPassword(event.target.value)}
                    />
                </div>
            </div>
            <div className={'row'}>
                <button className={'action-button'} onClick={closeCard}>Cancel</button>
                <button className={'action-button add'} onClick={createAdmin}>Create Admin</button>
            </div>
        </div>
    )
}

const AdminList = () => {
    useTitle('Admins - St. Matthew Newspaper Admin Settings');

    const [ loading, setLoading ] = useState(false);
    const [ admins, setAdmins ] = useState([]);
    const [ addAdminShown, setAddAdminShown ] = useState(false);

    const sortAdmins = (a, b) => a.name.localeCompare(b.name);

    const fetchAdmins = async () => {
        setLoading(true);
        const fetchedAdmins = await niceFetch('GET', 'schools/admins');
        setAdmins(fetchedAdmins);
        setLoading(false);
    }

    useEffect(() => {
        fetchAdmins();
    }, [])

    return (
        <section id={'admin-list'}>
            <div className={'top'}>
                <h2>Admins</h2>
                <button className={'action-button add'} onClick={() => setAddAdminShown(true)}>Add Admin</button>
                {addAdminShown ?
                    <AddAdminCard closeCard={() => setAddAdminShown(false)} fetchAdmins={fetchAdmins}/> : ''}
            </div>
            <p className={'subtitle'}>{pluralCount(admins.length, 'school admin')}</p>
            <div className={'grid-container'}>{
                loading ? <Loading/> :
                    admins.sort(sortAdmins).map((admin, index) =>
                        <AdminCard key={index} id={admin.id} name={admin.name} email={admin.email}/>
                    )
            }</div>
        </section>
    )
}

export default AdminList;