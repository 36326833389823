import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ArticleEditor } from '../components';
import { AddImageIcon, EditIcon } from '../../media';
import { Loading } from '../../common/components';
import { getImage, niceFetch, postImage, removeImage } from '../../common/utils';
import { useTitle } from '../../common/hooks';
import { saveImages } from '../../common/utils/handleImages';

const EditorPage = () => {
    const [ article, setArticle ] = useState();
    const [ status, setStatus ] = useState('');
    const [ saved, setSaved ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ error, setError ] = useState('');
    const [ articleContent, setArticleContent ] = useState();
    const [ query, setQuery ] = useSearchParams();

    const fetchArticle = async id => {
        const fetchedArticle = await niceFetch('GET', `articles/${id}`);
        if (fetchedArticle.error === 'Not Found') return setQuery({});

        setArticle(fetchedArticle);
        setTitle(fetchedArticle.title);
        setDescription(fetchedArticle.description);
        setThumbnailId(fetchedArticle.thumbnail);

        switch (fetchedArticle.status) {
            case 0:
                setStatus('draft');
                break;
            case 1:
                setStatus('pending');
                break;
            case 2:
                setStatus('published');
                break;
            case 3:
                setStatus('unpublished');
                break;
        }
    }

    useEffect(() => {
        if (query.get('id'))
            fetchArticle(query.get('id'));
    }, [ query ]);

    /*  Image Ids  */

    const addedImages = useRef([]);
    const removedImages = useRef([]);
    const addImageId = id => addedImages.current.push(id);
    const removeImageId = id => removedImages.current.push(id);

    /*  Head Editor (Title)  */

    const [ title, setTitle ] = useState('');
    const titleInputElement = useRef(null);
    useTitle(`${title ? title : 'Unnamed'} - Newspaper Editor`);

    const handleTitle = ignoreSave => {
        if (!ignoreSave) setSaved(false);
        setTitle(titleInputElement.current.value);
        titleInputElement.current.style.height = 'auto';
        titleInputElement.current.style.height = titleInputElement.current.scrollHeight + 'px';
    }

    useEffect(() => {
        if (article) handleTitle(article.title === title);
        else handleTitle(!article && !title);
    }, [ title ])

    /*  Head Editor (Description)  */

    const [ description, setDescription ] = useState('');
    const descriptionInputElement = useRef(null);

    const handleDescription = ignoreSave => {
        if (!ignoreSave) setSaved(false);
        setDescription(descriptionInputElement.current.value);
        descriptionInputElement.current.style.height = 'auto';
        descriptionInputElement.current.style.height = descriptionInputElement.current.scrollHeight + 'px';
    }

    useEffect(() => {
        if (article) handleDescription(article.description === description);
        else handleDescription(!article && !description);
    }, [ description ])

    /*  Head Editor (Thumbnail)  */

    const [ thumbnailLoading, setThumbnailLoading ] = useState(false);
    const [ thumbnailId, setThumbnailId ] = useState('');

    const handleThumbnail = async event => {
        const file = event.target.files[0];
        const validFileTypes = [ 'image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/svg+xml' ];

        if (file && validFileTypes.includes(file.type)) {
            setThumbnailLoading(true);

            if (thumbnailId)
                removeImageId(thumbnailId);

            const { image: { id } } = await postImage(file, article ? article.id : null);
            setThumbnailId(id);
            addImageId(id);
        }
    }

    /*  Handle Back  */

    const navigate = useNavigate();

    const handleBack = async () => {
        navigate('../');
    }

    /*  Saving and Stuff  */

    // called when editor is left (or closed)⤵
    useEffect(() => () => addedImages.current.forEach(removeImage), []);

    const getArticleBody = required => {
        const body = {};
        if (!required) {
            body.title = '';
            body.description = '';
            body.content = '';
            body.thumbnail = '';

            if (title)
                body.title = title;

            if (description)
                body.description = description;

            if (articleContent)
                body.content = articleContent;

            if (thumbnailId)
                body.thumbnail = thumbnailId;
        } else {
            body.title = title;
            body.description = description ? description : ''; // optional description
            body.content = articleContent;
            body.thumbnail = thumbnailId;

            if (body.title === '') {
                setError('You need a title.');
                return null;
            } else if (body.content === '') {
                setError('You need article content.')
                return null;
            } else if (body.thumbnail === '') {
                setError('You need a thumbnail.')
                return null;
            }
        }

        setError('');
        return body;
    }

    const save = async (articleId = null) => {
        setSaved(true);
        setSaving(false);

        if (articleId) await saveImages(addedImages.current, articleId);
        removedImages.current.forEach(removeImage);

        addedImages.current = [];
        removedImages.current = [];
    }

    const saveDraft = async () => {
        if (saving) return;
        setSaving(true);
        const response = await niceFetch('POST', 'articles?post=false', { body: getArticleBody(false) });
        fetchArticle(response.id);
        save(response.id);
    }

    const savePost = async () => {
        if (saving) return;
        const body = getArticleBody(true);

        if (body) {
            setSaving(true);
            const response = await niceFetch('POST', 'articles?post=true', { body });
            fetchArticle(response.id);
            save(response.id);
        }
    }

    const postDraft = async () => {
        if (!article || saving) return;
        const body = getArticleBody(true);

        if (body) {
            setSaving(true);
            await niceFetch('PATCH', `articles/post/${article.id}`, { body });
            save();
        }
    }

    const saveArticle = async (draft = false) => {
        if (!article || saving) return;
        const body = getArticleBody(!draft);

        if (body) {
            setSaving(true);
            await niceFetch('PATCH', `articles/${article.id}`, { body });
            save();
        }
    }

    return (
        <div id={'editor-page'}>
            <header>
                <h1>Newspaper Article Online Editor</h1>
                <div className={'save-bar'}>
                    <div className={'left'}>
                        <span className={'back-button'} onClick={handleBack}>&lt; Go Back</span>
                        <span className={`save-label ${error ? 'error' : saved || saving ? 'saved' : 'not-saved'}`}>
                            {error ? error : saving ? 'Saving...' : saved ? 'Saved' : 'Don’t forget to save!'}
                        </span>
                    </div>
                    <div className={'save-buttons'}>
                        {
                            !article || status === 'draft' ?
                                <button
                                    className={'draft save-button'}
                                    onClick={status === 'draft' ? () => saveArticle(true) : saveDraft}
                                >
                                    Save as Draft
                                </button> : ''
                        }
                        {
                            !article || status === 'draft' || status === 'unpublished' ?
                                <button className={'post save-button'}
                                        onClick={status === 'draft' ? postDraft : savePost}>Save and Post</button> :
                                <button className={'post save-button'} onClick={saveArticle}>Save</button>
                        }
                    </div>
                </div>
            </header>
            <main>
                <div className={'head-editor'}>
                    {
                        thumbnailId || thumbnailLoading ?
                            <Fragment>
                                {thumbnailLoading ? <Loading className={'thumbnail-loading'}/> : ''}
                                <img
                                    className={'thumbnail-preview'}
                                    src={getImage(thumbnailId)} alt={''}
                                    onLoad={() => setThumbnailLoading(false)}
                                    style={{ display: thumbnailLoading ? 'none' : 'block' }}
                                />
                                <div className={'thumbnail-field'}
                                     style={{ display: thumbnailLoading ? 'none' : 'flex' }}>
                                    <label className={'thumbnail-button'} for={'thumbnail-input'}>
                                        <img src={EditIcon} alt={''}/>
                                        <span>Change Thumbnail</span>
                                    </label>
                                    <input
                                        id={'thumbnail-input'}
                                        className={'thumbnail-input'} onChange={handleThumbnail}
                                        type={'file'} accept={'.png, .jpeg, .jpg, .gif, .webp, .svg'}
                                    />
                                </div>
                            </Fragment> :
                            <div className={'thumbnail-field'}>
                                <label className={'thumbnail-button'} for={'thumbnail-input'}>
                                    <img src={AddImageIcon} alt={''}/>
                                    <span>Add a Thumbnail</span>
                                </label>
                                <input
                                    id={'thumbnail-input'}
                                    className={'thumbnail-input'} onChange={handleThumbnail}
                                    type={'file'} accept={'.png, .jpeg, .jpg, .gif, .webp, .svg'}
                                />
                            </div>
                    }
                    <textarea
                        className={'title-input'} placeholder={'Add Title'} value={title}
                        ref={titleInputElement} onChange={handleTitle} rows={1} autoFocus
                    />
                    <textarea
                        className={'description-input'} placeholder={'Add description'} value={description}
                        ref={descriptionInputElement} onChange={handleDescription} rows={1}
                    />
                </div>
                <ArticleEditor
                    article={article} setArticleContent={setArticleContent} unsave={() => setSaved(false)}
                    addImageId={addImageId} removeImageId={removeImageId}
                />
            </main>
        </div>
    )
}

export default EditorPage;