import { Editor, Element as SlateElement, Point, Range } from 'slate';

/**  Thanks to Slate Examples! Link: https://github.com/ianstormtaylor/slate/blob/main/site/examples/tables.tsx  **/
/**  Edited by me  **/

const withTables = editor => {
    const { deleteBackward, deleteForward, insertBreak, deleteFragment } = editor

    editor.deleteBackward = unit => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [ cell ] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table-cell',
            })

            if (cell) {
                const [ , cellPath ] = cell
                const start = Editor.start(editor, cellPath)

                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
        }

        deleteBackward(unit)
    }

    editor.deleteForward = unit => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [ cell ] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table-cell',
            })

            if (cell) {
                const [ , cellPath ] = cell
                const end = Editor.end(editor, cellPath)

                if (Point.equals(selection.anchor, end)) {
                    return
                }
            }
        }

        deleteForward(unit)
    }

    editor.insertBreak = () => {
        const { selection } = editor

        if (selection) {
            const [ table ] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table',
            })

            if (table) {
                return
            }
        }

        insertBreak()
    }

    editor.deleteFragment = unit => {
        const { selection } = editor

        if (selection) {
            const [ cell ] = Editor.nodes(editor, {
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === 'table-cell',
            })

            if (cell) {
                const [ , cellPath ] = cell
                const start = Editor.start(editor, cellPath)
                const end = Editor.end(editor, cellPath)

                if ((Point.equals(selection.anchor, start) || Point.equals(selection.focus, end)) &&
                    !(Point.equals(selection.anchor, start) && Point.equals(selection.focus, end))) {
                    return
                }
            }
        }

        deleteFragment(unit);
    }

    return editor
}

export { withTables };