import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { EditIcon, MoreIcon, ReadIcon } from '../../media';
import { articleLink, getImage, niceFetch } from '../../common/utils';

const ArticleCard = ({ id, title, thumbnail, published: _published, status: _status, archived, fetchArticles }) => {
    const navigate = useNavigate();

    /*  Status Stuff  */
    let status;
    switch (_status) {
        case 0:
            status = 'draft';
            break;
        case 1:
            status = 'pending';
            break;
        case 2:
            status = 'published';
            break;
        case 3:
            status = 'unpublished';
            break;
    }

    let statusLabel = ''; // default is blank (not undefined)
    if (archived) statusLabel = 'Archived';
    else if (status === 'draft') statusLabel = 'Draft';
    else if (status === 'pending') statusLabel = 'Pending for Approval';
    else if (status === 'published') statusLabel = 'Published';
    else if (status === 'unpublished') statusLabel = 'Unpublished';

    /*  Date Stuff  */
    const published = _published ? DateTime.fromISO(_published).toFormat('LLLL d, y') : '';

    let dateLabel = ''; // default is blank (not undefined)
    if (status === 'published' || status === 'unpublished')
        dateLabel = ` - ${published}`;

    /*  Fetches  */

    const read = () => window.open(`../../${articleLink(id, title)}`);

    const edit = () => navigate(`../editor?id=${id}`);

    const republish = async () => {
        await niceFetch('PATCH', `articles/publishing/${id}?action=publish`)
        fetchArticles();
    }

    const unpublish = async () => {
        await niceFetch('PATCH', `articles/publishing/${id}?action=unpublish`)
        fetchArticles();
    }

    const deleteArticle = async () => {
        await niceFetch('DELETE', `articles/${id}`)
        fetchArticles();
    }

    return (
        <div className={'article-card'}>
            {thumbnail ? <img className={'thumbnail'} src={getImage(thumbnail)} alt={''}/> :
                <div className={'no-thumbnail'}/>}
            <div className={'content'}>
                <p className={'overline'}>
                    <span className={`status ${archived ? 'archived' : status}`}>{statusLabel}</span>
                    <span className={'date'}>{dateLabel}</span>
                </p>
                <h2 className={`title ${title ? title : 'unnamed'}`}>{title ? title : 'Unnamed'}</h2>
                <div className={'actions'}>
                    {
                        status === 'draft' ? '' :
                            <button className={'read'} onClick={read}>
                                <img className={'icon'} src={ReadIcon} alt={''}/>
                                <span>Read</span>
                            </button>
                    }
                    {
                        archived ? '' :
                            <Fragment>
                                <button className={'edit'} onClick={edit}>
                                    <img className={'icon'} src={EditIcon} alt={''}/>
                                    <span>Edit</span>
                                </button>
                                <div className={'dropdown'}>
                                    <button className={'icon-button'}><img src={MoreIcon} alt={''}/></button>
                                    <div className={'menu'}>
                                        {
                                            status === 'unpublished' ?
                                                <div className={'item'} onClick={republish}>Republish</div> :
                                                status === 'published' ?
                                                    <div className={'item'} onClick={unpublish}>Unpublish</div> : ''
                                        }
                                        <div className={'item'} onClick={deleteArticle}>Delete</div>
                                    </div>
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default ArticleCard;