import dotenv from 'dotenv';

dotenv.config();

export const getImage = id => process.env.REACT_APP_ENV === 'DEV' ?
    `http://localhost:3020/12345678/images/${id}` :
    `https://mattnewsapi.djeumene.ca/12345678/images/${id}`; // Basically GET fetch

export const postImage = async (file, articleId) => {
    const data = new FormData();
    data.append('file', file);

    const response = await fetch(
        process.env.REACT_APP_ENV === 'DEV' ?
            `http://localhost:3020/12345678/images${articleId ? `?article=${articleId}` : ''}` :
            `https://mattnewsapi.djeumene.ca/12345678/images${articleId ? `?article=${articleId}` : ''}`,
        { method: 'POST', credentials: 'include', body: data }
    );
    return response.json();
}

export const removeImage = async id => {
    const response = await fetch(
        process.env.REACT_APP_ENV === 'DEV' ?
            `http://localhost:3020/12345678/images/${id}` :
            `https://mattnewsapi.djeumene.ca/12345678/images/${id}`, {
            method: 'DELETE',
            credentials: 'include'
        });
    return response.json();
}

export const saveImages = async (ids, articleId) => {
    const response = await fetch(
        process.env.REACT_APP_ENV === 'DEV' ?
            `http://localhost:3020/12345678/images?article=${articleId}` :
            `https://mattnewsapi.djeumene.ca/12345678/images?article=${articleId}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ images: ids })
        });
    return response.json();
}