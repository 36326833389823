import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as JsSearch from 'js-search';
import { DateTime } from 'luxon';

import { Card } from '../components';
import { DownArrowIcon, SearchIcon } from '../../media';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const statusLabels = [
    '📰 When you finish reading an article, click on one of the emojis at the bottom 😀',
    '📰 How much articles did you read today? 🤔',
    '🎮 Video games aren’t trendy anymore 🥱 People read articles now! 📰👍',
    'Have you heard of this new trendy trend!? 😮 It’s called reading articles! 😁',
    'Enjoy reading articles about video games 🎮, education 📚, sports 🏀 and more 😄!',
    '📰 I bet you can’t beat my record of reading 6 articles in one day! 😁 Try! 😀',
    'This website recently got a new redesign! 😄 What do you think? 👍',
    'I think this website looks so much better? 🤩 What is your opinion on the new redesign? 👍',
];

const HomePage = () => {
    useTitle('Welcome to the St. Matthew Online Newspaper!');
    const description = 'Great entertaining and informative articles written by Mr. White’s Class.';

    const [ showButton, setShowButton ] = useState(false);
    const [ bannerStatus, setBannerStatus ] = useState('');

    useEffect(() => {
        setBannerStatus(statusLabels[Math.floor(Math.random() * statusLabels.length)]);
    }, [])

    document.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 256 && !showButton)
            setShowButton(true);
        else if (document.documentElement.scrollTop <= 256 && showButton)
            setShowButton(false);
    })

    /*  Articles and Filters  */

    const [ loading, setLoading ] = useState(false);
    const [ articles, setArticles ] = useState([]);
    const [ archives, setArchives ] = useState({});
    const [ selectedArchive, setSelectedArchive ] = useState();

    const sortArticles = (a, b) => {
        if (!a || !b) return 0;
        const timeA = DateTime.fromISO(a.published).valueOf();
        const timeB = DateTime.fromISO(b.published).valueOf();
        return timeB - timeA;
    }

    const fetchArticles = async () => {
        // since articles are forcefully loaded after archives, loading is set to true in `fetchArchives`
        const fetchedArticles = await niceFetch('GET', `articles?archive=${selectedArchive}`);
        setArticles(fetchedArticles.sort(sortArticles));
        setLoading(false);
    }

    const fetchArchives = async () => {
        setLoading(true);
        const fetchedArchives = await niceFetch('GET', 'archives');

        const sortArchives = (a, b) => b.start - a.start;
        const archivesList = {};
        fetchedArchives.sort(sortArchives).forEach(archive => {
            archivesList[archive.id] = archive.start + '-' + archive.end;
            if (archive.current) setSelectedArchive(archive.id);
        });
        setArchives(archivesList);
    }

    useEffect(() => { fetchArchives() }, []);
    useEffect(() => {
        if (selectedArchive) fetchArticles();
    }, [ selectedArchive ]);

    /*  Search and Filter  */

    const [ searchValue, setSearchValue ] = useState('');
    const [ articleItems, setArticleItems ] = useState([]);
    const [ selectedMonth, setSelectedMonth ] = useState('All');
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthFilter = article => {
        const month = DateTime.fromISO(article.published).toFormat('LLLL');
        return selectedMonth === 'All' ? true : month === selectedMonth;
    }

    const search = new JsSearch.Search('id');
    search.addDocuments(articles);
    search.addIndex('title');
    search.addIndex('authorName');
    search.addIndex('description');

    useEffect(() => {
        if (searchValue === '')
            setArticleItems(articles.filter(monthFilter));
        else
            setArticleItems(search.search(searchValue).filter(monthFilter));
    }, [ articles, searchValue, selectedMonth ]);

    return (
        <Fragment>
            <div className={'status-banner'}>{bannerStatus}</div>
            <div id={'hero'}>
                <p className={'login'}>
                    Login as
                    <Link to={'student'}>Student</Link>or
                    <Link to={'admin'}>Teacher</Link>
                </p>
                <h1 className={'title'}>St. Matthew Online Newspaper</h1>
                <p className={'description'}>{description}</p>
                <p className={'article-count'}>There {pluralCount(articles.length, 'article', true)}</p>
            </div>
            <div className={'search-bar'}>
                <div className={'search-field'}>
                    <img className={'icon'} src={SearchIcon} alt={''}/>
                    <input
                        className={'field'}
                        placeholder={'Search for articles and authors...'}
                        onChange={event => setSearchValue(event.target.value)}
                    />
                </div>
                <div className={'filter-group'}>
                    <div className={'archive-field'}>
                        <img className={'icon'} src={DownArrowIcon} alt={''}/>
                        <select className={'field'} onChange={
                            event => setSelectedArchive(Object.keys(archives).find(id => archives[id] === event.target.value))
                        }>{Object.values(archives).map((archive, index) => <option
                            key={index}>{archive}</option>)}</select>
                    </div>
                    <div className={'month-field'}>
                        <img className={'icon'} src={DownArrowIcon} alt={''}/>
                        <select className={'field'} onChange={event => setSelectedMonth(event.target.value)}>
                            <option>All</option>
                            {months.map((month, index) => <option key={index}>{month}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div
                id={'top-btn'} className={showButton ? '' : 'hide'}
                onClick={() => document.documentElement.scrollTop = 0}
            >
                Go Back Up
            </div>
            {
                loading ? <Loading image/> :
                    <main className={'articles'}>{
                        articleItems.length === 0 ? <p className={'no-articles-text'}>There's nothing here...</p> :
                            articleItems.map((article, index) =>
                                <Card
                                    id={article.id} key={index}
                                    title={article.title} author={article.authorName}
                                    description={article.description}
                                    thumbnail={article.thumbnail}
                                    published={article.published}
                                />
                            )
                    }</main>
            }
        </Fragment>
    )
}

export default HomePage;