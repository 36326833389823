import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';

import { evaluateSync } from '@mdx-js/mdx';
import * as provider from '@mdx-js/react';
import * as runtime from 'react/jsx-runtime';
import remarkGfm from 'remark-gfm';
import { rehypeFix } from './rehypeFix';

import { FunnyEmoji, HappyEmoji, InterestingEmoji, LeftCaretIcon, LikeEmoji, LoveEmoji } from '../../media';
import { adminLoggedInState, studentLoggedInState } from '../../states';
import { Loading } from '../../common/components';
import { niceFetch, articleLink as getArticleLink, getImage } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const ArticleImage = ({ id, align, caption }) => (
    <div className={`image ${align}`}>
        <img src={getImage(id)} alt={''}/>
        {caption ? <p className={'caption'}>{caption}</p> : ''}
    </div>
)

const Article = () => {
    const [ loading, setLoading ] = useState(false);
    const [ article, setArticle ] = useState({});
    const { article: articleLink } = useParams();
    const navigate = useNavigate();

    const adminLoggedIn = useRecoilValue(adminLoggedInState);
    const studentLoggedIn = useRecoilValue(studentLoggedInState);

    const fetchArticle = async () => {
        setLoading(true);
        const idFromLink = articleLink.slice(articleLink.length - 16, articleLink.length); // 16 is the id length
        const fetchedArticle = await niceFetch('GET', `articles/${idFromLink}`);
        if (
            fetchedArticle.error === 'Not Found' ||
            getArticleLink(fetchedArticle.id, fetchedArticle.title) !== articleLink
        ) return navigate('../not-found'); // if article not found or link isn't valid, go back to 404 page and stop function

        switch (fetchedArticle.status) {
            case 0: // Draft
            case 3: // Unpublished
                return navigate('../not-found');
            case 1: // Pending
                if (adminLoggedIn) break;
                else if (studentLoggedIn) {
                    const fetchedStudent = await niceFetch('GET', 'students/current');
                    if (fetchedStudent.id === fetchedArticle.authorId) break;
                } else return navigate('../not-found');
        } // allow published articles

        setArticle(fetchedArticle);
        setLoading(false);
    }

    useEffect(() => { fetchArticle() }, []);

    const {
        id, title, description, authorName: author,
        published: _published, content, status, reactions = {}
    } = article;
    useTitle(title ? `${title} - St. Matthew Newspaper` : 'St. Matthew Newspaper');
    const published = DateTime.fromISO(_published).toFormat('LLLL d, y');

    const mdxComponents = {
        h1: props => <h2 className={'heading'} {...props}/>,
        B: props => <b {...props}/>,
        I: props => <i {...props}/>,
        U: props => <u {...props}/>,
        a: props => <a {...props} target={'_blank'}/>,
        Tint: props => <span {...props} className={'tint'} style={{ color: props.color }}/>,
        Image: ArticleImage
    };

    const { default: MDXContent } = evaluateSync(content, {
        ...provider, ...runtime,
        remarkPlugins: [ remarkGfm ],
        rehypePlugins: [ rehypeFix ]
    });

    /*  Reactions  */

    const [ reaction, setReaction ] = useState();
    const reactionEmojis = {
        'like': LikeEmoji,
        'love': LoveEmoji,
        'interesting': InterestingEmoji,
        'funny': FunnyEmoji,
        'happy': HappyEmoji
    };
    const handleReact = emoji => {
        if (reaction) niceFetch('PUT', `articles/${id}/reactions/${emoji}?previous=${reaction}`);
        else niceFetch('PUT', `articles/${id}/reactions/${emoji}`);
        setReaction(emoji);
    }
    const reactionCount = emoji => !reactions[emoji] ? (reaction === emoji ? 1 : 0) : reactions[emoji] + (reaction === emoji ? 1 : 0);
    useEffect(() => { reactionCount(reaction) }, [ reaction ]);

    return (
        <main className={'article-page'}>
            <button className={'sticky-back-button'} onClick={() => navigate('../')}>
                <img src={LeftCaretIcon} alt={''}/>
                <span>Go Home</span>
            </button>
            <header>
                <button className={'back-button'} onClick={() => navigate('../')}>
                    <img src={LeftCaretIcon} alt={''}/>
                    <span>Go Home</span>
                </button>
                <p className={'school-title'} onClick={() => navigate('../')}>St. Matthew Online Newspaper</p>
            </header>
            {
                loading ? <Loading image/> :
                    <Fragment>
                        <h1 className={'title'}>{title}</h1>
                        {description ? <p className={'description'}>{description}</p> : ''}
                        {
                            status === 2 ?
                                <p className={'info'}>Written by <span>{author}</span> <br/>on <span>{published}</span>
                                </p> :
                                <p className={'info'}>Written by <span>{author}</span> (pending for approval)</p>
                        }
                        <article className={'content'}>
                            <MDXContent components={mdxComponents}/>
                        </article>
                        {status === 2 ? <div className={'reactions'}>
                            <h3 className={'heading'}>Give this article a reaction!</h3>
                            <div className={'emojis'}>{
                                Object.entries(reactionEmojis).map(([ emoji, emojiIcon ], index) =>
                                    <div className={`emoji ${reaction === emoji ? 'reacted' : ''}`} key={index}>
                                        <img src={emojiIcon} alt={''} onClick={() => handleReact(emoji)}/>
                                        <span
                                            className={reactionCount(emoji) === 0 ? 'zero' : reactionCount(emoji) < 5 ? 'mid' : ''}
                                        >{reactionCount(emoji)}</span>
                                    </div>
                                )
                            }</div>
                        </div> : ''}
                    </Fragment>
            }
        </main>
    )
}

export default Article;