import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import './styles/admin.css';
import {
    AdminPage, AdminLogin,
    PendingArticles, PublishedArticles, ManageArchives, StudentList, AdminList
} from './pages';
import { adminLoggedInState } from '../states';

const AdminRoutes = () => {
    const adminLoggedIn = useRecoilValue(adminLoggedInState);

    return (
        <Routes>
            <Route path={'/login'} element={<AdminLogin/>}/>
            <Route path={'/'} element={adminLoggedIn ? <AdminPage/> : <Navigate to={'login'} replace/>}>
                <Route index element={<Navigate to={'pending'} replace/>}/>
                <Route path={'pending'} element={<PendingArticles/>}/>
                <Route path={'articles'} element={<PublishedArticles/>}/>
                <Route path={'archives'} element={<ManageArchives/>}/>
                <Route path={'students'} element={<StudentList/>}/>
                <Route path={'admins'} element={<AdminList/>}/>
                <Route path={'*'} element={<Navigate to={'pending'} replace/>}/>
            </Route>
        </Routes>
    )
}

export default AdminRoutes;