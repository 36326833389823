import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomeRoutes from './home';
import AdminRoutes from './admin';
import StudentRoutes from './student';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path={'/*'} element={<HomeRoutes/>}/>
                <Route path={'/admin/*'} element={<AdminRoutes/>}/>
                <Route path={'/student/*'} element={<StudentRoutes/>}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;