import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { studentLoggedInState } from '../../states';
import { niceFetch } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const StudentLogin = () => {
    useTitle('Student Login - St. Matthew Online Newspaper');

    const [ isStudentLoggedIn, setStudentLoggedIn ] = useRecoilState(studentLoggedInState);

    const navigate = useNavigate();

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState('');

    const handleLoginIn = async () => {
        if (!username) {
            setError(''); // reset error so animation works every click
            // also set timeout is needed for animation every click as well
            setTimeout(() => { setError('username') }, 10);
            return;
        }

        const body = { username, password };
        const data = await niceFetch('POST', 'students/auth/login', { body });

        if (!data.error) setStudentLoggedIn(true);
        else {
            setError(''); // reset error so animation works every click
            // also set timeout is needed for animation every click as well
            setTimeout(() => { setError('password') }, 10);
        }
    }

    useEffect(() => {
        if (isStudentLoggedIn)
            navigate('articles');
    })

    return (
        <div className={'login-container student'}>
            <div className={'login-card'}>
                <Link to={'../..'} className={'back-button'}>&lt; Back</Link>
                <h1>Student Login</h1>
                <div className={`text-field ${error === 'username' ? 'wrong' : ''}`}>
                    <p>Username</p>
                    <input
                        type={'text'} placeholder={'Type username...'}
                        onChange={event => setUsername(event.target.value)}
                    />
                </div>
                <div className={`text-field ${error === 'password' ? 'wrong' : ''}`}>
                    <p>Password</p>
                    <input
                        type={'password'} placeholder={'Type password...'}
                        onChange={event => setPassword(event.target.value)}
                    />
                </div>
                <button onClick={handleLoginIn}>Login</button>
            </div>
        </div>
    )
}

export default StudentLogin;