import React from 'react';
import { DateTime } from 'luxon';
import { CancelIcon, CheckIcon, ReadIcon } from '../../media';
import { articleLink, niceFetch } from '../../common/utils';

const PendingCard = ({ id, title, author, timestamp: _timestamp, fetchArticles }) => {
    const timestampFormat = 'ccc, LLLL d, y (h:mm a)';
    const timestamp = DateTime.fromISO(_timestamp).toFormat(timestampFormat);

    /*  Fetches  */

    const accept = async () => {
        await niceFetch('PATCH', `articles/pending/${id}?status=accept`)
        fetchArticles();
    }

    const reject = async () => {
        await niceFetch('PATCH', `articles/pending/${id}?status=reject`)
        fetchArticles();
    }

    const read = () => {
        // Open in new tab
        window.open(`../../${articleLink(id, title)}`);
    }

    return (
        <div className={'article-card'}>
            <div className={'content'}>
                <p className={'detail'}>Sent on: <span>{timestamp}</span></p>
                <h3>{title}</h3>
                <p className={'detail'}>Written by <span>{author}</span></p>
            </div>
            <div className={'actions'}>
                <button className={'action-button'} onClick={read}>
                    <img className={'icon'} src={ReadIcon} alt={''}/>
                    <span>Read</span>
                </button>
                <button className={'action-button add'} onClick={accept}>
                    <img className={'icon'} src={CheckIcon} alt={''}/>
                    <span>Accept</span>
                </button>
                <button className={'action-button delete'} onClick={reject}>
                    <img className={'icon'} src={CancelIcon} alt={''}/>
                    <span>Reject</span>
                </button>
            </div>
        </div>
    )
}

export default PendingCard;