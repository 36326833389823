import React, { useEffect, useState } from 'react';
import { LoadingImage } from '../../media';

const Loading = props => {
    const [ dotCount, setDotCount ] = useState(1);

    useEffect(() => {
        setTimeout(() => setDotCount(dotCount === 3 ? 1 : dotCount + 1), 400);
    }, [ dotCount ])

    return props.image ? (
        <div className={'loading-image'}>
            <img src={LoadingImage} alt={''}/>
            <p className={`loading ${props.className ?? ''}`}>Loading{'.'.repeat(dotCount)}</p>
        </div>
    ) : <p className={`loading ${props.className ?? ''}`}>Loading{'.'.repeat(dotCount)}</p>
}

export default Loading;