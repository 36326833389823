import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { StudentPage, Articles, Drafts, StudentLogin, EditorPage } from './pages';
import { studentLoggedInState } from '../states';

const StudentRoutes = () => {
    const studentLoggedIn = useRecoilValue(studentLoggedInState);

    return (
        <Routes>
            <Route path={'/login'} element={<StudentLogin/>}/>
            <Route path={'/'} element={studentLoggedIn ? <StudentPage/> : <Navigate to={'login'} replace/>}>
                <Route index element={<Navigate to={'articles'}/>} replace/>
                <Route path={'articles'} element={<Articles/>}/>
                <Route path={'drafts'} element={<Drafts/>}/>
                <Route path={'*'} element={<Navigate to={'articles'} replace/>}/>
            </Route>
            <Route path={'editor'} element={<EditorPage/>}/>
        </Routes>
    )
}

export default StudentRoutes;