import React, { useEffect, useState } from 'react';
import { niceFetch, pluralCount } from '../../common/utils';
import { DownArrowIcon } from '../../media/index.js';

const ArchiveCard = ({ id, start, end, current, articleCount, studentCount }) => {
    const [ students, setStudents ] = useState([]);
    const [ studentsShown, setStudentsShown ] = useState(false);

    const fetchStudents = async () => {
        const fetchedStudents = await niceFetch('GET', `students?archive=${id}`);
        setStudents(fetchedStudents);
    }

    useEffect(() => {
        fetchStudents();
    }, [])

    return (
        <div className={'archive-card'}>
            <h3 className={current ? 'current' : ''}>
                {start} - {end}
                <span className={`tag ${!current ? 'archived' : ''}`}>{current ? 'Present' : 'Archived'}</span>
            </h3>
            <div className={'details'}>
                <span>{pluralCount(articleCount, 'article')}</span>
                <span className={`students-label ${studentsShown ? 'active' : ''}`}
                      onClick={() => setStudentsShown(!studentsShown)}>
                    {pluralCount(studentCount, 'student')}
                    <img src={DownArrowIcon} alt={''}/>
                </span>
            </div>
            {
                studentsShown ?
                    <div className={'students'}>
                        {
                            students.map(student => student.name).sort()
                                .map((name, index) => <span key={index}>{name}</span>)
                        }
                    </div> : ''
            }
        </div>
    )
}

export default ArchiveCard;