import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { HomePage, Article, NotFound } from './pages';

const HomeRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/not-found'} element={<NotFound/>}/>
            <Route path={'/:article'} element={<Article/>}/>
        </Routes>
    )
}

export default HomeRoutes;