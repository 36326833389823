import React, { useEffect, useState } from 'react';
import { ArchiveCard } from '../components';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const ManageArchives = () => {
    useTitle('School Year Archives - St. Matthew Newspaper Admin Settings');

    const [ loading, setLoading ] = useState(false);
    const [ archives, setArchives ] = useState([]);

    /*  Fetches  */

    const sortArchives = (a, b) => b.start - a.start;
    const fetchArchives = async () => {
        setLoading(true);
        const fetchedArchives = await niceFetch('GET', 'archives');
        setArchives(fetchedArchives);
        setLoading(false);
    }

    const create = async () => {
        await niceFetch('POST', 'archives')
        fetchArchives();
    };

    useEffect(() => { fetchArchives() }, []);

    return (
        <section id={'archive-list'}>
            <div className={'top'}>
                <h2>School Year Archives</h2>
                <button className={'action-button add'} onClick={create}>Create New Archive</button>
            </div>
            <p className={'subtitle'}>{pluralCount(archives.length, 'set')}</p>
            <div className={'grid-container'}>{
                loading ? <Loading/> :
                    archives.sort(sortArchives).map((archive, index) =>
                        <ArchiveCard key={index} {...archive}/>
                    )
            }</div>
        </section>
    )
}

export default ManageArchives;