import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { articleLink, getImage } from '../../common/utils';

const Card = ({ id, title, author, description, thumbnail, published }) => {
    const month = DateTime.fromISO(published).toFormat('LLLL y');
    const navigate = useNavigate();

    return (
        <div className={'article-card'} onClick={() => navigate(articleLink(id, title))}>
            <img className={'thumbnail'} src={getImage(thumbnail)} alt={''}/>
            <div className={'content'}>
                <p className={'month'}>{month}</p>
                <h2 className={'title'}>{title}</h2>
                {description ? <p className={'description'}>{description}</p> : ''}
                <p className={'author'}>By <span>{author}</span></p>
            </div>
        </div>
    )
}

export default Card;