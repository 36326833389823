const AdminCard = ({ id, name, email }) => {
    return (
        <div className={'admin-card'}>
            <p className={'name'}>{name}</p>
            <h3 className={'email'}>{email}</h3>
            <div className={'actions'}>
                {/*<button className={'action-button ghost'}>Reset Password</button>*/}
                <button className={'action-button delete'}>Delete</button>
            </div>
        </div>
    )
}

export default AdminCard;