export const pluralCount = (count, singularNoun, includeVerb = false) => {
    let noun = singularNoun;
    const verb = count === 1 ? 'is' : 'are';
    let label;

    if (count === 1) label = `${count} ${noun}`;
    else {
        if (noun.endsWith('y')) {
            const pluralNoun = noun.slice(0, noun.length - 1) + 'ies';
            label = `${count} ${pluralNoun}`;
        } else if (noun.endsWith('s'))
            label = `${count} ${noun}es`;
        else label = `${count} ${noun}s`;
    }

    return includeVerb ? `${verb} ${label}` : label;
}