import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { PendingCard } from '../components';
import { Loading } from '../../common/components';
import { niceFetch, pluralCount } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const PendingArticles = () => {
    useTitle('Pending Articles - St. Matthew Newspaper Admin Settings');

    const [ loading, setLoading ] = useState(false);
    const [ articles, setArticles ] = useState([]);

    const sortArticles = (a, b) => {
        if (!a || !b) return 0;
        const timeA = DateTime.fromISO(a.sent).valueOf();
        const timeB = DateTime.fromISO(b.sent).valueOf();
        return timeB - timeA;
    }

    /*  Fetches  */

    const fetchArticles = async () => {
        setLoading(true);
        const fetchedArticles = await niceFetch('GET', 'articles/pending');
        setArticles(fetchedArticles);
        setLoading(false);
    }

    const acceptAll = async () => {
        await niceFetch('PATCH', 'articles/pending?status=accept')
        fetchArticles();
    }

    const rejectAll = async () => {
        await niceFetch('PATCH', 'articles/pending?status=reject')
        fetchArticles();
    }

    useEffect(() => { fetchArticles() }, []);

    return (
        <section id={'pending-articles'}>
            <div className={'top'}>
                <h2>Pending Articles</h2>
                <button className={'action-button add'} onClick={acceptAll}>Accept All</button>
                <button className={'action-button delete'} onClick={rejectAll}>Reject All</button>
            </div>
            <p className={'subtitle'}>{pluralCount(articles.length, 'pending article')}</p>
            <div className={'grid-container'}>{
                loading ? <Loading/> :
                    articles.sort(sortArticles).map((article, index) =>
                        <PendingCard
                            key={index} id={article.id} title={article.title}
                            author={article.authorName} timestamp={article.sent}
                            fetchArticles={fetchArticles}
                        />
                    )
            }</div>
        </section>
    )
}

export default PendingArticles;