import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ArticleCard } from '../components';
import { CreateIcon } from '../../media';
import { Loading } from '../../common/components';
import { niceFetch } from '../../common/utils';
import { useTitle } from '../../common/hooks';

const Articles = () => {
    useTitle('Your Articles - Online Newspaper');

    const navigate = useNavigate();
    const [ studentId, setStudentId ] = useState(''); // default blank id
    const [ articles, setArticles ] = useState();

    const sortArticles = (a, b) => {
        if (!a || !b) return 0;
        const timeA = DateTime.fromISO(a.published ? a.published : a.sent).valueOf();
        const timeB = DateTime.fromISO(b.published ? b.published : b.sent).valueOf();
        return timeB - timeA;
    }

    const fetchStudent = async () => {
        const fetchedStudent = await niceFetch('GET', 'students/current');
        setStudentId(fetchedStudent.id);
    }

    const fetchArticles = async () => {
        const fetchedArticles = await niceFetch('GET', `articles?author=${studentId}`);
        setArticles(fetchedArticles.filter(article => article.status > 0)); // 0 = draft; 1,2,3 = not draft
    }

    useEffect(() => {
        fetchStudent();
    }, []);

    useEffect(() => {
        if (studentId) fetchArticles();
    }, [ studentId ]);

    return (
        <section className={'article-grid'}>
            <div className={'new-card'} onClick={() => navigate('../editor')}>
                <img src={CreateIcon} alt={''}/>
                <span>Write New Article</span>
            </div>
            {
                !articles ? <Loading/> :
                    articles.sort(sortArticles).map((article, index) =>
                        <ArticleCard
                            key={index} id={article.id}
                            title={article.title} thumbnail={article.thumbnail}
                            status={article.status} published={article.published}
                            archived={article.archived} fetchArticles={fetchArticles}
                        />
                    )
            }
        </section>
    )
}

export default Articles;